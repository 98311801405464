import React,{useState,useContext} from 'react'
import axios from 'axios';
import config from '../api/config'
axios.defaults.withCredentials = true
const CertificateContext=React.createContext();

export function useCertificate(){
    return useContext(CertificateContext)
}

export function CertificateContextprovider({children}){
const [userCertificates,setUserCertificates]=useState([]);
const [selectedCertificateId,setSelectedCertificateId]=useState("");
const[message,setMessage]=useState({header:"",text:"",variant:"",buttons:[],isVisible:false})
const[selectedCertificate,setSelectedCertificate]=useState({})
const [actionType,setActionType]=useState("")
const [editModalIsVisible,setEditModalIsVisible]=useState(false)

const getCertificatesByIds=async(idsArray)=>{
    const api= axios.create({baseURL:`${config.apiBaseURL}/certificates`})
    await api.post('/'+idsArray).then(res=>{
        
        setUserCertificates(res.data)
        
    } )
    .catch(error=> console.log(`Error: $error`));
}


const getUserCertificates=async()=>{
    const api= axios.create({baseURL:`${config.apiBaseURL}/certificates`})
        await api.get('/').then(res=>{
            
            setUserCertificates(res.data)
            
        } )
        .catch(error=> console.log(`Error: $error`));
    }

    const saveCertificate=()=>{

        let emptyCertificate={title:"Certificate title",acheivement:"Acheivement description",issuedBy:"Issuing Authority",certificateFor:"Course",frameworkID:"",programID:"",courseID:""}
        
        let api=axios.create({baseURL:`${config.apiBaseURL}/certificates`})
        api.post('/', emptyCertificate).then(response=>{ 
            
            getUserCertificates()
            setSelectedCertificateId(response.data._id)
        
        })
       
        
       
    }
    const updateCertificate=async(certificateID)=>{
        
        let api=axios.create({baseURL:`${config.apiBaseURL}/certificates`});
        
        await api.put('/'+certificateID, {
        ...selectedCertificate
        })
        .then(response => {
            console.log(response);
            getUserCertificates();
        })
        .catch(error => {
            console.log(error);
        });
    }
    const removeCertificate=async (certificateID)=>{
        console.log(certificateID)
        const api= axios.create({baseURL:`${config.apiBaseURL}/certificates`})
        await api.delete('/'+ certificateID)
        await getUserCertificates()
        
       
    }

    
    const value={
        userCertificates,
        selectedCertificateId,
        selectedCertificate,
        message,
        getUserCertificates,
        removeCertificate,
        setEditModalIsVisible,
        editModalIsVisible,
        setSelectedCertificateId,
        setSelectedCertificate,
        setMessage,
        updateCertificate,
        saveCertificate,
        actionType,
        setActionType
    }

    return (
        <CertificateContext.Provider value={value}>
            {children}
        </CertificateContext.Provider>
    )
}

export default CertificateContextprovider;