import React, { useState, useEffect } from 'react';
import { Col, Row, Form, Button, Image, Alert } from 'react-bootstrap';
import './ProfileManager.css';
import { useSettings } from '../context/SettingContext';
import { useAuth } from '../context/AuthContext';

export default function ProfileManager() {
  const { updateUserDetails, getUserDetails } = useAuth();
  const { getSettingByName, settingData } = useSettings();
  const [countries, setCountries] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [jobTitles, setJobTitles] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [originalDetails, setOriginalDetails] = useState({});
  const [previewUrl, setPreviewUrl] = useState(''); // To store the preview URL
  const [message, setMessage] = useState({ text: '', success: null, show: false }); // Message state

  // Fetch and update data for countries, industries, and job titles
  useEffect(() => {
    const populateData = async () => {
      await getSettingByName('countries');
      await getSettingByName('industries');
      await getSettingByName('jobTitles');
    };

    populateData();
  }, []);

  // Update state when settingData changes
  useEffect(() => {
    if (settingData?.countries) setCountries(settingData.countries);
    if (settingData?.industries) setIndustries(settingData.industries);
    if (settingData?.jobTitles) setJobTitles(settingData.jobTitles);
  }, [settingData]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      const user = await getUserDetails(); // Assuming this function fetches user data from the backend
      if (user) {
        const userDetailsWithFullName = { ...user.details, fullName: user.fullName };
        setUserDetails(userDetailsWithFullName);
        setOriginalDetails(userDetailsWithFullName); // Store the original details for cancelation

        // Set preview URL if profile picture is available
        if (user.details?.profilePicture) {
          setPreviewUrl(user.details.profilePicture);
        }
      }
    };

    fetchUserDetails();
  }, [getUserDetails]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserDetails({ ...userDetails, [name]: value });
    // Hide the message when user interacts with the form
    if (message.show) setMessage({ text: '', success: null, show: false });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Image = reader.result;
        setUserDetails({ ...userDetails, profilePicture: base64Image });
        setPreviewUrl(base64Image); // Set preview URL to the base64 image
      };
      reader.readAsDataURL(file); // Convert image to base64 string
    }
    // Hide the message when user interacts with the form
    if (message.show) setMessage({ text: '', success: null, show: false });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const result = await updateUserDetails(userDetails);

    // Show success or failure message
    if (result.success) {
      setMessage({ text: "Profile updated successfully!", success: true, show: true });
      setOriginalDetails(userDetails); // Update original details on successful save
    } else {
      setMessage({ text: result.message || "An error occurred while updating the profile.", success: false, show: true });
    }
  };

  const handleCancel = () => {
    // Revert to original details and preview
    setUserDetails(originalDetails);
    setPreviewUrl(originalDetails?.profilePicture || '');
    setMessage({ text: 'Changes reverted.', success: true, show: true });
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row className="justify-content-md-center">
          <Col lg={7} md={7} sm={12} xs={12}>
            {/* Display success or error message */}
            {message.show && (
              <Alert variant={message.success ? 'success' : 'danger'}>
                {message.text}
              </Alert>
            )}

            {/* Full Name */}
            <Row>
              <Col lg="3" md="4" xs="5" sm="4" className="appEditorLabel frameworkEditorLabel">
                <Form.Label>Full Name</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  name="fullName"
                  type="text"
                  value={userDetails.fullName || ''}
                  onChange={handleInputChange}
                />
              </Col>
            </Row>

            {/* Work Phone Number */}
            <Row>
              <Col lg="3" md="4" xs="5" sm="4" className="appEditorLabel frameworkEditorLabel">
                <Form.Label>Work Phone Number</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  name="workPhone"
                  type="text"
                  value={userDetails.workPhone || ''}
                  onChange={handleInputChange}
                />
              </Col>
            </Row>

            {/* Address */}
            <Row>
              <Col lg="3" md="4" xs="5" sm="4" className="appEditorLabel frameworkEditorLabel">
                <Form.Label>Address</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  name="address"
                  type="text"
                  value={userDetails.address || ''}
                  onChange={handleInputChange}
                />
              </Col>
            </Row>

            {/* Country */}
            <Row>
              <Col lg="3" md="4" xs="5" sm="4" className="appEditorLabel frameworkEditorLabel">
                <Form.Label>Country</Form.Label>
              </Col>
              <Col>
                <Form.Select
                  name="country"
                  value={userDetails.country || ''}
                  onChange={handleInputChange}
                >
                  {countries.map((item, index) => (
                    <option key={`country${index}`} value={item}>
                      {item}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Row>

            {/* Industry */}
            <Row>
              <Col lg="3" md="4" xs="5" sm="4" className="appEditorLabel frameworkEditorLabel">
                <Form.Label>Industry</Form.Label>
              </Col>
              <Col>
                <Form.Select
                  name="industry"
                  value={userDetails.industry || ''}
                  onChange={handleInputChange}
                >
                  {industries.map((item, index) => (
                    <option key={`industry${index}`} value={item}>
                      {item}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Row>

            {/* Job Title */}
            <Row>
              <Col lg="3" md="4" xs="5" sm="4" className="appEditorLabel frameworkEditorLabel">
                <Form.Label>Job Title</Form.Label>
              </Col>
              <Col>
                <Form.Select
                  name="jobTitle"
                  value={userDetails.jobTitle || ''}
                  onChange={handleInputChange}
                >
                  {jobTitles.map((item, index) => (
                    <option key={`jobTitle${index}`} value={item}>
                      {item}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Row>

            {/* Profile Picture Upload */}
            <Row className="mt-3">
              <Col lg="3" md="4" xs="5" sm="4" className="appEditorLabel frameworkEditorLabel">
                <Form.Label>Profile Picture</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  name="profilePicture"
                  type="file"
                  onChange={handleFileChange}
                />
              </Col>
            </Row>

            {/* Submit and Cancel Buttons */}
            <Row className="mt-3">
              <Col>
                <Button type="submit">Save</Button>
                <Button variant="secondary" className="ms-2" onClick={handleCancel}>Cancel</Button>
              </Col>
            </Row>
          </Col>
          <Col>
            {/* Profile Picture Preview */}
            {previewUrl && (
              <Row className="mt-3">
                <Col lg="12" md="12" xs="12" sm="12" className="appEditorLabel frameworkEditorLabel">
                  <Form.Label>Preview</Form.Label>
                  <div><Image src={previewUrl} alt="Profile Preview" thumbnail /></div>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Form>
    </>
  );
}
