import React from 'react'
import { Form,Row, Col} from 'react-bootstrap'
import './CompetencyEditor.css'
import { FaTrashAlt } from 'react-icons/fa'
import { useFramework } from '../context/FrameworkContext'

export default function CompetencyEditor(props) {
  const {changeSingleValue,competencySettings,removeCompetency,setMessage}=useFramework();

  const handleInputChange=(event)=>{
    console.log(event.targer)
    let fieldValue=event.target.value;
    let fieldName=event.target.name;
    
    changeSingleValue(fieldName,fieldValue,props.roleID,props.functionID,props.id);
  }
  const handleDeleteCompetency=()=>{
    setMessage(
    { header:"Deleting a Competency",
      text:"Are yuo sure you want to delete this competency",
      variant:'warning',
      isVisibile:true,
      buttons:[{text:"Delete Competency",action:removeTheCompetency},{text:"Cancel",action:hideTheMessage}]

    }
    )
  }

  const hideTheMessage=()=>{
    setMessage({header:"",text:"",variant:"",buttons:[],isVisible:false})
  }
  const removeTheCompetency=()=>{
    removeCompetency(props.roleID,props.functionID,props.id)
    hideTheMessage();
    
  }
  return (
    <>
    <div className="competencyEditorBlock">
       <Row>
        <Col lg="11" md="11" sm="11" xs="11" className="editorLabel">
        <Row>
            <Col > 
            <div>Name</div>
            <Form.Control name="name"
                          size="sm" 
                          type='text' 
                          defaultValue={props.name}
                          onChange={handleInputChange}>
            </Form.Control>
            
            </Col>
            <Col> 
            <div>Description</div>
            <Form.Control name="description"
                          size="sm" 
                          type='text' 
                          defaultValue={props.description}
                          onChange={handleInputChange}>
            </Form.Control>
            
            </Col>
            </Row>
            <Row>
            
            <Col>
            <div>Type</div>
            <Form.Select  size="sm"
                          type='text'
                          name="type"
                          onChange={handleInputChange}>
                {competencySettings.type.map((element)=>{
                    return(<option value={element.label}>{element.label}</option>)
                })}
               
            </Form.Select>
            </Col>
            
            
            <Col>
            <div>Level</div>
            <Form.Select  size="sm"
                          type='text'
                          name="level"
                          onChange={handleInputChange}>
                {competencySettings.level.map((element)=>{
                    return(<option value={element.label}>{element.label}</option>)
                })}
               
            </Form.Select>
            </Col>
            <Col>
            <div>Status</div>
            <Form.Select  size="sm"
                          type='text'
                          name="status"
                          onChange={handleInputChange}>
                {competencySettings.status.map((element)=>{
                    return(<option value={element.label}>{element.label}</option>)
                })}
               
            </Form.Select>
            </Col>
         <p></p>  
         <hr/> 
        </Row>
        </Col>
        <Col lg="1" md="1" sm="1" xs="1">
        <div className="appIcon"> <FaTrashAlt onClick={handleDeleteCompetency}/> </div>
       
        </Col>
       </Row>        
    </div>
    
    
    
    </>
  )
}
