import{React} from 'react'
import {Routes,Route} from 'react-router-dom';
import HomePage from './pages/HomePage';
import NotFoundPage from './pages/NotFoundPage';
import SignUpPage from './pages/SignUpPage';
import SignInPage from './pages/SignInPage';
import FrameworksManagementPage from './pages/FrameworksManagementPage';
import ProgramsManagementPage from './pages/ProgramsManagementPage';
import CoursesManagementPage from './pages/CoursesManagementPage';
import SettingsPage from './pages/SettingsPage';
import HelpPage from './pages/HelpPage';
import CertificationPage from './pages/CertificationPage';
import AboutUsPage from './pages/AboutUsPage';
import PricingAndPlansPage from './pages/PricingAndPlansPage';
import LearningResourcesPage from './pages/LearningResourcesPage';
import ContactUsPage from './pages/ContactUsPage';
import RequireAuth from './components/RequireAuth';
import AccountActivationPage from './pages/AccountActivationPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import ReportsPage from './pages/ReportsPage';
import TermsOfServicePage from './pages/TermsOfServicePage';
import CookiesPolicyPage from './pages/CookiesPolicyPage';
import MainLayout from './layouts/MainLayout';
import ChangePasswordPage from './pages/ChangePasswordPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage'; 
import ProfileManagerPage from  './pages/ProfileManagerPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
export default function AppRoutes() {
  
  return (
    <Routes>
      
      {/* Public Routes */}

        <Route exact path="/" element={<MainLayout><HomePage/></MainLayout>} /> 
        <Route path="/signup" element={<MainLayout><SignUpPage /></MainLayout>} />
        <Route path="/signin" element={<MainLayout><SignInPage /></MainLayout>} />
        <Route path="/about-us" element={<MainLayout><AboutUsPage /></MainLayout>} />
        <Route path="/pricing-and-plans" element={<MainLayout><PricingAndPlansPage /></MainLayout>} />
        <Route path="/home" element={<MainLayout><HomePage /></MainLayout>} />
        <Route path="/learning-resources" element={<MainLayout><LearningResourcesPage /></MainLayout>} />
        <Route path="/contact-us" element={<MainLayout><ContactUsPage /></MainLayout>} />
        <Route path="/activation/:token" element={<MainLayout><AccountActivationPage /></MainLayout>} />
        <Route path="/privacy-policy" element={<MainLayout><PrivacyPolicyPage /></MainLayout>} />
        <Route path="/terms-of-service" element={<MainLayout><TermsOfServicePage /></MainLayout>} />
        <Route path="/cookies-policy" element={<MainLayout><CookiesPolicyPage /></MainLayout>} />
        
        <Route path="/forgot-password" element={<MainLayout><ForgotPasswordPage /></MainLayout>} />
        <Route path="/reset-password" element={<MainLayout><ResetPasswordPage /></MainLayout>} />
      {/* User Routes */}
      
          <Route path="/frameworks" element={<RequireAuth ><MainLayout><FrameworksManagementPage /></MainLayout></RequireAuth>} />    
          <Route path="/programs" element={<RequireAuth><MainLayout><ProgramsManagementPage /></MainLayout></RequireAuth>} />
          <Route path="/settings" element={<RequireAuth><MainLayout><SettingsPage /></MainLayout></RequireAuth>} />
          <Route path="/courses" element={<RequireAuth><MainLayout><CoursesManagementPage /></MainLayout></RequireAuth>} />
          <Route path="/help" element={<RequireAuth><MainLayout><HelpPage /></MainLayout></RequireAuth>} />
          <Route path="/certification" element={<RequireAuth><MainLayout><CertificationPage /></MainLayout></RequireAuth>} />
          <Route path="/reports" element={<RequireAuth><MainLayout><ReportsPage /></MainLayout></RequireAuth>} />
          <Route path="/change-password" element={<RequireAuth><MainLayout><ChangePasswordPage /></MainLayout></RequireAuth>} />
          <Route path="/manage-profile" element={<RequireAuth><MainLayout><ProfileManagerPage /></MainLayout></RequireAuth>} />
       
      {/* User Routes */}
      
      <Route path="*" element={<MainLayout><NotFoundPage /></MainLayout>} />
      
    </Routes>
  )
}
