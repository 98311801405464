import React from 'react'
import {Row,Col,Container,Button} from 'react-bootstrap'
import {FaWalking,FaBiking,FaCarSide,FaRocket,FaCheckCircle,FaInfoCircle,FaTimesCircle   } from 'react-icons/fa'
import {PayPalScriptProvider,PayPalButtons} from '@paypal/react-paypal-js'
import { useNavigate } from 'react-router-dom'
import './PricingPlans.css'
import PayPalPaymentComponent from './PayPalPaymentComponent'
export default function PricingPlans() {
  const navigate = useNavigate();
  const handleGetStartedClick = () => {
    navigate('/signup');
  }
  return (
    <>
    <Container>
     
    <Row>
    <Col>
    <Row >
    <Col>  
        <div className="planContainer">
            <h2 class="planTitle">Basic</h2>
            <div className="planLogo"><FaWalking/></div>
            
            <div className="planPrice">Free</div><p>15 days trial</p>
            <div className="planText">
              <p>
                Free trial, with ability to use most of the available features:
                <ul>
                  <li>
                  <FaCheckCircle/> Creating multiple frameworks
                  </li>
                  <li>
                  <FaCheckCircle/> Manage Programs
                  </li>
                  <li>
                  <FaCheckCircle/> Manage Courses Information
                  </li>
                  <li>
                  <FaCheckCircle/> Use AI Capabilities
                  </li>
                  <li>
                  <FaCheckCircle/> Manage Certificates
                  </li>
                  <li>
                  <FaCheckCircle/> Generate Reports
                  </li>
                  <li>
                  <FaTimesCircle/> Publishing Frameworks
                  </li>
                </ul>
             
              </p>
           
            </div>
           
            <div className="d-grid gap-2">
            <Button onClick={handleGetStartedClick} className="planButton" variant="primary" size="lg">Get Started</Button>
            </div>
        </div>
      </Col>
      <Col>  
        <div className="planContainer">
            <h2 class="planTitle">Personal</h2>
            <div className="planLogo"><FaBiking/></div>
            
            <div className="planPrice">$19.99</div><p>/month - Billed annualy</p>
            <div className="planText">
              <p>
              The Personal Plan, great for indviduals who want to create their own frameworks:
              <ul>
                  <li>
                  <FaInfoCircle /> Creating up to <strong>(3)</strong> frameworks
                  </li>
                  <li>
                  <FaCheckCircle/> Manage Programs
                  </li>
                  <li>
                  <FaCheckCircle/> Manage Courses Information
                  </li>
                  <li>
                  <FaTimesCircle /> Use AI Capabilities
                  </li>
                  <li>
                  <FaCheckCircle/> Manage Certificates
                  </li>
                  <li>
                  <FaCheckCircle/> Generate Reports
                  </li>
                  <li>
                  <FaTimesCircle/> Publishing Frameworks
                  </li>
                </ul>
              </p>
           
            </div>
            <p></p>
            <p></p>
            <div className="d-grid gap-2">
            <PayPalPaymentComponent id="1" price="239.88"></PayPalPaymentComponent>
            </div>
        </div>
      </Col>
      <Col>  
        <div className="planContainer">
            <h2 class="planTitle">Standard</h2>
            <div className="planLogo"><FaCarSide/></div>
            
            <div className="planPrice">$39.99</div><p>/month - Billed annualy</p>
            <div className="planText">
              
              <p>
              The Standard Plan, includes all the features except for publishing frameworks Capabilities:
              <ul>
                  <li>
                  <FaCheckCircle/> Creating unlimited frameworks
                  </li>
                  <li>
                  <FaCheckCircle/> Manage Programs
                  </li>
                  <li>
                  <FaCheckCircle/> Manage Courses Information
                  </li>
                  <li>
                  <FaCheckCircle/> Use AI Capabilities
                  </li>
                  <li>
                  <FaCheckCircle/> Manage Certificates
                  </li>
                  <li>
                  <FaCheckCircle/> Generate Reports
                  </li>
                  <li>
                  <FaTimesCircle/> Publishing Frameworks
                  </li>
                </ul>
              </p>
              
           
            </div>
            <p></p>
            <p></p>
            <div className="d-grid gap-2">
             <PayPalPaymentComponent id="2" price="479.88"></PayPalPaymentComponent>
            </div>
        </div>
      </Col>
      <Col>  
        <div className="planContainer">
            <h2 class="planTitle">Premium</h2>
            <div className="planLogo"><FaRocket/></div>
            
            <div className="planPrice">$99.99</div>/month - Billed annualy
            <div className="planText">
            <p>
              The Standard Plan, includes all the features except for publishing frameworks Capabilities:
              <ul>
                  <li>
                  <FaCheckCircle/> Creating unlimited frameworks
                  </li>
                  <li>
                  <FaCheckCircle/> Manage Programs
                  </li>
                  <li>
                  <FaCheckCircle/> Manage Courses Information
                  </li>
                  <li>
                  <FaCheckCircle/> Use AI Capabilities
                  </li>
                  <li>
                  <FaCheckCircle/> Manage Certificates
                  </li>
                  <li>
                  <FaCheckCircle/> Generate Reports
                  </li>
                  <li>
                  <FaCheckCircle/> Publishing Frameworks
                  </li>
                </ul>
              </p>
           
            </div>
            <p></p>
            <p></p>
            <div className="d-grid gap-2">
            <PayPalPaymentComponent id="3" price="1199.88"></PayPalPaymentComponent>
            </div>
        </div>
      </Col>
      
    </Row>
    </Col>
    </Row>
    </Container>
    
    </>
  )
}
