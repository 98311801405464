import React, { useEffect} from 'react'
import CollapsableItem from './CollapsableItem'
import {Form,Row,Col,Button} from 'react-bootstrap'
import { useHelpCategory } from '../context/HelpCategoriesContext';
import { FaSearch } from 'react-icons/fa';
import './HelpCategoriesList.css'
export default function HelpCategoriesList(props) {
    const {getHelpCategories,userHelpCategories}=useHelpCategory();

  useEffect(()=>{
    getHelpCategories(props.categoryName);
  },[]) 

  return (
    <>
    {userHelpCategories?.map((category)=>{
        return (
            <>
            <Row className="helpCategoryTitle justify-content-md-center">
              
              <Col lg={5}> <Form.Control type="text" name="search" ></Form.Control></Col><Col lg={5}><Button className="searchButton"><FaSearch/></Button></Col>
            </Row>
            <Row>
            <Col>
            {category.subjects?.map((subject,index)=>{
                return(
                <CollapsableItem
                key={index}
                subject={subject}/>
                )
            
           
            })}
            </Col>
            </Row>
            </>
        )

    })}
   
   </>
  )
}
