// components/Layout.js
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './MainLayout.css';
const MainLayout = ({ children }) => {
  return (
    <div className="wrapper">
      <Header />
      <div className="pageContainer">
      
      
        {children}
      </div>
      <Footer />
    </div>
  );
};

export default MainLayout;
