import React from 'react'
import {Navbar,Container,Nav} from 'react-bootstrap'
import LoginDropDown from './LoginDropDown'
import logo from '../assets/logo.png' ;
import { useAuth } from '../context/AuthContext';
import frameworkIcon from '../assets/frameworkIcon.svg'
import {FaBook,FaInfo,FaRegEnvelope,FaTags,FaCog,FaHome, FaTable,FaChalkboardTeacher,FaQuestion,FaCertificate,FaWpforms,FaSignInAlt, FaEye,FaUserPlus} from 'react-icons/fa'
import './Header.css'

export default function Header() {
  const {user,isAuthenticated,setIsAuthenticated}=useAuth();
  
  
  return (
    <>
    
    <Navbar bg="dark"  expand="lg" variant="dark"   style={{fontSize:'small'}}>
    <Container >
      <Navbar.Brand href="#home"><img src={logo} alt="Logo" style={{width:'250px'}} /></Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto " >
        
        
        {(!isAuthenticated)?<><Nav.Link href="home"><span className="itemIcon"><FaHome/></span> Home |</Nav.Link>
          <Nav.Link href="pricing-and-plans"><span className="itemIcon"><FaTags/></span> Pricing & Plans |</Nav.Link>
          <Nav.Link href="learning-resources"><span className="itemIcon"><FaBook/></span> Learning Resources |</Nav.Link>
          <Nav.Link href="about-us"><span className="itemIcon"><FaInfo/></span> About Us |</Nav.Link>
          <Nav.Link href="contact-us"><span className="itemIcon"><FaRegEnvelope/></span> Contact Us |</Nav.Link>
          
          <Nav.Link href="privacy-policy"><span className="itemIcon"><FaEye/></span> Privacy Policy</Nav.Link>
          <Nav.Link href="signin"><span className="itemIcon"><FaSignInAlt/></span> Sign In |</Nav.Link>
          <Nav.Link href="signup"><span className="itemIcon"><FaUserPlus/></span> Sign up |</Nav.Link>
          </>
          :
          <>
          <Nav.Link href="/frameworks"><span ><img src={frameworkIcon} style={{width:'16px'}}/></span> Frameworks |</Nav.Link>
          <Nav.Link href="/programs"><span className="itemIcon"><FaTable/></span> Programs |</Nav.Link>
          <Nav.Link href="/courses"><span className="itemIcon"><FaChalkboardTeacher/></span> Courses |</Nav.Link>
          <Nav.Link href="/certification"><span className="itemIcon"><FaCertificate/></span> Certification |</Nav.Link>
          <Nav.Link href="/reports"><span className="itemIcon"><FaWpforms/></span> Reports</Nav.Link> 
          <Nav.Link href="/help"><span className="itemIcon"><FaQuestion/></span> Help</Nav.Link>
          
          <LoginDropDown/>
          <Nav.Link href="settings"><span className="itemIcon"><FaCog/></span></Nav.Link>
          </>
        }
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>

   </>
  )
}
