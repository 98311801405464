import React,{useEffect} from 'react'
import ProgramView from './ProgramView'
import GenericList from './GenericList'
import {useProgram} from '../context/ProgramContext'
import{Row,Col} from 'react-bootstrap'
import './ProgramsList.css'
export default function ProgramsList() {
  const {getUserPrograms,setActionType,setEditModalIsVisible,removeProgram,saveProgram,userPrograms,selectedProgramId,setSelectedProgramId,message,setMessage,setSelectedProgram,selectedProgram}=useProgram();
  
  useEffect(()=>{
    getUserPrograms();  
    setMessage({target:"Programs",header:"",text:"",variant:"",buttons:[],isVisible:false});
  },[]);

  const handleSelect=async(event)=>{
    await setSelectedProgramId(event.target.getAttribute("selectid"))
    let currentProgram=await userPrograms.find(program=>program._id===event.target.getAttribute("selectid"))
    if (currentProgram) {setSelectedProgram(currentProgram)}
  }
  const handleDelete=(event)=>{
    setSelectedProgramId(event.target.getAttribute("deleteid"));
    setMessage({target:"Programs",header:"Are you sure ?",text:"Are you sure you want to delete this program and all its related information?",variant:"warning",buttons:[{text:"Yes, I am sure",action:handleCompleteDelete},{text:"Cancel",action:handleCancel}],isVisible:true})

  }
  const handleCompleteDelete=()=>{
    removeProgram(selectedProgramId);
    setMessage({target:"Programs",header:"",text:"",variant:"",buttons:[],isVisible:false});
  }
  const handleCancel=()=>{
    setMessage({target:"Programs",header:"",text:"",variant:"",buttons:[],isVisible:false});
  }
  const handleEdit=async(event)=>{
    await setActionType("edit")
    await setSelectedProgramId(event.target.getAttribute("editid"));
    let currentProgram=await userPrograms.find(program=>program._id===event.target.getAttribute("editid"))
    if (currentProgram) {await setSelectedProgram(currentProgram)}
    setEditModalIsVisible(true)
  }
  const handleAdd=async()=>{
    await saveProgram()

    
  }

  return (
    <div className="programListContainer">
    <Row>
      <Col lg="4" md="4" sm="6" sx="12">
      <GenericList
        listHeader="Programs"
        columnsHeaders={["Name","Description"]}
        columnsNames={["name","description"]}
        data={userPrograms}
        handleSelect={handleSelect}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        handleAdd={handleAdd}
        selectedItemId={selectedProgramId}
        listMessage={message}
      />
      </Col>
      <Col lg="8" md="8" sm="6" sx="12">
      
      <ProgramView
        data={selectedProgram}
      />
      </Col>
    </Row>

    </div>
  )
}




