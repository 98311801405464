import React,{useState,useContext} from 'react'
import axios from 'axios';
import config from '../api/config'
axios.defaults.withCredentials = true
const SettingContext=React.createContext();

export function useSettings(){
    return useContext(SettingContext)
}

export function SettingContextprovider({children}){
const[settingData,setSettingData]=useState({})

const getSettingByName=async(settingName)=>{
    const api= axios.create({baseURL:`${config.apiBaseURL}/settings`})
        await api.get('/'+settingName).then(res=>{
            let oldSettings=settingData
            
            setSettingData({...oldSettings,[settingName]:res.data[0].info})

        } )
        .catch(error=> console.log(`Error: $error`));
}

const getUserSettings=async()=>{
    const api= axios.create({baseURL:`${config.apiBaseURL}/settings`})
        await api.get('/').then(res=>{
            setSettingData(res.data)
        } )
        .catch(error=> console.log(`Error: $error`));
    }
 
    const value={
        getSettingByName,
        settingData
    }

    return (
        <SettingContext.Provider value={value}>
            {children}
        </SettingContext.Provider>
    )
}

export default SettingContextprovider;