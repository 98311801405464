import React,{useEffect} from 'react'
import { Container} from 'react-bootstrap'
import CertificatesList from '../components/CertificatesList';
import Header from '../components/Header';
import CertificateContextprovider from '../context/CertificateContext';
import CertificateEditorModal from '../components/CertificateEditorModal';
import SettingContextprovider from '../context/SettingContext';
import {FaCertificate} from 'react-icons/fa'

export default function CertificationPage() {
  
  return (
   <>

   
   <CertificateContextprovider>
    <SettingContextprovider>
        <CertificateEditorModal/>
        <p className="appPageIcon"><FaCertificate/></p>
        
        <h2 className="appPageTitle"> | Certificates Management</h2>

        <CertificatesList/>
    </SettingContextprovider>
  </CertificateContextprovider>




   </>
  )
}
