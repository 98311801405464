
import React, { useState, useEffect } from 'react';
import axios from 'axios';
const AccountActivator= ({ token }) => {
    
    const [status, setStatus] = useState('loading');

    useEffect(() => {
        let isMounted = true; // Track if the component is mounted
        const activateAccount = async () => {
            try {
                const response = await axios.get(`/auth/activate/${token}`)
            
                if (isMounted) {
                    if (response?.status===200) setStatus('success');
                    // Optionally redirect after a delay
                    // setTimeout(() => navigate('/login'), 3000);
                }
            }
            
            catch(error) {
                if (isMounted) {
                    const status = error.response ? error.response.status : 500; // More robust error handling
                    switch (status) {
                        case 404:
                            setStatus('notfound');
                            break;
                        case 400:
                            setStatus('alreadyactivated');
                            break;
                        default:
                            setStatus('error');
                            break;
                    }
                }
            }
        };


        activateAccount();
        return () => {
            isMounted = false; // Cleanup flag when the component unmounts
        };
    },[token]);

    const renderAlert = () => {
        switch (status) {
            case 'loading':
                return <div className="alert alert-info">Loading...</div>;
            case 'success':
                return <div className="alert alert-success">Account activated successfully!</div>;
            case 'notfound':
                return <div className="alert alert-danger">User not found.</div>;
            case 'alreadyactivated':
                return <div className="alert alert-warning">Account already activated.</div>;
            case 'error':
                return <div className="alert alert-danger">An error occurred while trying to activate the account.</div>;
            default:
                return null;
        }
    };

    return (
        <div>
            {renderAlert()}
        </div>
    );
}

export default AccountActivator;