import React, {useContext,useState,useEffect} from "react";
import axios from 'axios';
import { useAuth } from "./AuthContext";
import config from "../api/config";
import {v4 as uuid} from 'uuid'; 
axios.defaults.withCredentials = true
const FrameworkContext=React.createContext();

export function useFramework(){
    return useContext(FrameworkContext)
}

export function FrameworkContextprovider({children}){

const [userFrameworks,setUserFrameworks]=useState([]);
 
   
   
    const competencySettings={
        type:[
            {label:'Core Competency',color:'#04cdff'},
            {label:'Technical Competency',color:'#17df03'},
            {label:'Soft Competency',color:'#ffa301'},
            {label:'Other Type',color:'#8601ff'},
            {label:'Core Competency',color:'ff66f2'},
        ],
        level:[
            {label:'Knowledge',color:'#ffb3ba'},
            {label:'Skills',color:'#8601ff'},
            {label:'Behavior',color:'#ff66f2'}

        ],
        status:[
            {label:'In Progress',color:'#ffb3ba'},
            {label:'Completed',color:'#17df03'},
            {label:'No Attempted',color:'#ffa301'},
            {label:'Pending',color:'8601ff'},
            {label:'Not Completed',color:'ff66f2'},

        ],

    }

   
    const [selectedFrameworkID,setSelectedFrameworkID]=useState(0)
    const[framework,setFramework]=useState({frameworkName:"",frameworkDescription:"",roles:[]});
    const [selectedCompetency,setSelectedCompetency]=useState({})
    const [tooltipPosition,setTooltipPosition]=useState({})
    const [tooltipIsVisible,setTooltipIsVisible]=useState('hidden')
    const [coloredBy,setColoredBy]=useState('type');
    const [editModalIsVisible,setEditModalIsVisible]=useState(false)
    const [message,setMessage]=useState({header:"",text:"",variant:"",buttons:[{text:""}],isVisible:false})
    
   
    const getFrameworks=async ()=>{
    try {
        const api=axios.create({baseURL:`${config.apiBaseURL}/frameworks`})
        const res=await api.get('/')
        setUserFrameworks(res.data)
        const selectedFrameIndex = await res.data.findIndex((framework) => framework._id === selectedFrameworkID);
        if (selectedFrameIndex > -1) {
        setFramework(res.data[selectedFrameIndex]);
        } else {
        setFramework(res.data[0]);
}
        } catch (error) {
            console.log(`Error: ${error}`);
        }
    }

    const getSingleFramework=async(frameworkID)=>{
        
        let api=axios.create({baseURL:`${config.apiBaseURL}/frameworks`});
        
        await api.get('/'+frameworkID)
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error);
            });
                }
    const addEmptyFramework=()=>{
        let emptyFramework={frameworkName:"the famework name placeholder",frameworkDescription:'the famework description placeholder',roles:[]}
        
        let api=axios.create({baseURL:`${config.apiBaseURL}/frameworks`})
        api.post('/', emptyFramework).then(response=>{ 
            
            getFrameworks()
            setSelectedFrameworkID(response.data._id)
        
        })
       
        
       
    }
    const updateFramework=async(frameworkID)=>{
        console.log(framework)
        let api=axios.create({baseURL:`${config.apiBaseURL}/frameworks`});
        
        await api.put('/'+frameworkID, {
        ...framework
        })
  .then(response => {
    console.log(response);
    getFrameworks();
  })
  .catch(error => {
    console.log(error);
  });
    }


    const removeFramework=async (frameworkID)=>{
        const api=await axios.create({baseURL:`${config.apiBaseURL}/frameworks`})
        await api.delete('/'+frameworkID)
            getFrameworks()
        
    }
    const addEmptyRole=()=>{
        let emptyRole={_id:uuid(),name:"",description:'',functions:[]}
        let updatedFramework={frameworkName:framework.name,frameworkDescription:framework.description,roles:[...(framework.roles),emptyRole]}
        setFramework(updatedFramework)
    }
    const removeRole=(roleID)=>{
        let updatedFramework={...framework}
        let toDeleteRoleIndex=updatedFramework.roles.findIndex((role)=>role._id===roleID);
        if (toDeleteRoleIndex>-1){
        updatedFramework.roles.splice(toDeleteRoleIndex,1);
        console.log(roleID+"has been deleted")
        setFramework(updatedFramework)}
    }
    const addEmptyFunction=(roleID)=>{
        let emptyFunction={_id:uuid(),name:"",description:'',competencies:[]}
        let updatedFramework={...framework}
        console.log(roleID)
        let toChangeRoleIndex= updatedFramework.roles.findIndex((role)=>role._id===roleID);
        if (toChangeRoleIndex>-1){
            console.log(toChangeRoleIndex)
            updatedFramework.roles[toChangeRoleIndex].functions.push(emptyFunction)
            setFramework(updatedFramework)
        } else {
            console.log('did not find the role')
        }
       
    }

    const removeFunction=(roleID,functionID)=>{
        let updatedFramework={...framework}
        let toDeleteRoleIndex=updatedFramework.roles.findIndex((role)=>role._id===roleID);
        if (toDeleteRoleIndex>-1){
            let toDeleteFunctionIndex= updatedFramework.roles[toDeleteRoleIndex].functions.findIndex((theFunction)=>theFunction._id===functionID);
            if (toDeleteFunctionIndex>-1){
                updatedFramework.roles[toDeleteRoleIndex].functions.splice(toDeleteFunctionIndex,1);
                console.log(functionID+"has been deleted")
                setFramework(updatedFramework)}
            }
            
    }
    const addEmptyCompetency=(roleID,functionID)=>{
        let emptyCompetency={_id:uuid(),name:"", description:"",
                                        level:competencySettings.level[0].label,
                                        type:competencySettings.type[0].label,
                                        status:competencySettings.status[0].label,
                                        requirementof:[]}
        let updatedFramework={...framework};
        console.log(functionID+"---- "+roleID)
        
        let toChangeRoleIndex=updatedFramework.roles.findIndex((role)=>role._id===roleID);
        console.log(updatedFramework.roles[toChangeRoleIndex].functions)
        if (toChangeRoleIndex>-1){
            let toChangeFunctionIndex= updatedFramework.roles[toChangeRoleIndex].functions.findIndex((theFunction)=>theFunction._id===functionID);
            if (toChangeFunctionIndex>-1){
                
                updatedFramework.roles[toChangeRoleIndex].functions[toChangeFunctionIndex].competencies.push(emptyCompetency)
                setFramework(updatedFramework)
            } else {
                console.log('did not find the function')
            }
        }
    }

    const removeCompetency=(roleID,functionID,competencyID)=>{
        let updatedFramework={...framework}
        let toDeleteRoleIndex=updatedFramework.roles.findIndex((role)=>role._id===roleID);
        if (toDeleteRoleIndex>-1){
            let toDeleteFunctionIndex= updatedFramework.roles[toDeleteRoleIndex].functions.findIndex((theFunction)=>theFunction._id===functionID);
            if (toDeleteFunctionIndex>-1){

                let toDeleteCompetencyIndex=updatedFramework.roles[toDeleteRoleIndex].functions[toDeleteFunctionIndex].competencies.findIndex((theCompetency)=>theCompetency._id===competencyID)
                if(toDeleteCompetencyIndex>-1){
                    updatedFramework.roles[toDeleteRoleIndex].functions[toDeleteFunctionIndex].competencies.splice(toDeleteCompetencyIndex,1);
                    console.log(competencyID+"has been deleted")
                    setFramework(updatedFramework)}
                }
            }
            
    }
    const toggleEditModal=()=>{
       
        if(editModalIsVisible)
        { setEditModalIsVisible(false)} else {
            setEditModalIsVisible(true)
        }
    }
    
    const changeSingleValue=(fieldName,fieldValue,roleID='*',functionID='*',competencyID='*')=>{
        let updatedFramework={...framework};
        console.log(fieldName+"-"+fieldValue+"-"+roleID)
        if (roleID!=='*'){
            let toChangeRoleIndex=updatedFramework.roles.findIndex((role)=>role._id===roleID); 
            
            if(functionID!=='*'){
                let toChangeFunctionIndex= updatedFramework.roles[toChangeRoleIndex].functions.findIndex((theFunction)=>theFunction._id===functionID);
                if (competencyID!=='*'){
                    let toChangeCompetencyIndex= updatedFramework.roles[toChangeRoleIndex].functions[toChangeFunctionIndex].competencies.findIndex((competency)=>competency._id===competencyID);
                    updatedFramework.roles[toChangeRoleIndex].functions[toChangeFunctionIndex].competencies[toChangeCompetencyIndex][fieldName]=fieldValue;
                } else {
                    updatedFramework.roles[toChangeRoleIndex].functions[toChangeFunctionIndex][fieldName]=fieldValue;
                }
            } else {
                
                updatedFramework.roles[toChangeRoleIndex][fieldName]=fieldValue;
            }
        }
        setFramework(updatedFramework);

    }

 


    const value={
        framework,
        selectedCompetency,
        setSelectedCompetency,
        setTooltipPosition,
        tooltipPosition,
        tooltipIsVisible,
        setTooltipIsVisible,
        addEmptyRole,
        removeRole,
        addEmptyFunction,
        addEmptyCompetency,
        setFramework,
        changeSingleValue,
        competencySettings,
        coloredBy,
        setColoredBy,
        editModalIsVisible,
        toggleEditModal,
        setEditModalIsVisible,
        message,
        setMessage,
        removeFunction,
        removeCompetency,
        userFrameworks,
        setUserFrameworks,
        selectedFrameworkID,
        setSelectedFrameworkID,
        addEmptyFramework,
        getFrameworks,
        removeFramework,
        updateFramework
    };

    return (
        <FrameworkContext.Provider value={value}>
            {children}
        </FrameworkContext.Provider>
    )
}
export default FrameworkContextprovider;