import React from 'react'
import { Col,Row} from 'react-bootstrap'
import CompetencyHoverTooltip from '../components/CompetencyHoverTooltip';
import Framework from '../components/Framework';
import FrameworkContextprovider from '../context/FrameworkContext';
import Header from '../components/Header';
import FrameworkEditorModal from '../components/FrameworkEditorModal';
import FrameworksList from '../components/FrameworksList';
import { useParams } from 'react-router-dom';
import frameworkIcon from '../assets/frameworkIcon.svg'


export default function FrameworksManagementPage() {
const {frameworkID}=useParams()
  return (
   <>
  
   <FrameworkContextprovider>
        <p className="appPageIcon"><img src={frameworkIcon} width="20px"/></p>
        <h2 className="appPageTitle"> | Frameworks Management</h2>
       <Row>
        <Col lg="3" md="3" sm="5" sx="12">
          {frameworkID?<FrameworksList selectedFramwworkID={frameworkID}/>:<FrameworksList/>}
        </Col>
        <Col lg="9" md="9" sm="7" sx="12">
        <FrameworkEditorModal/>
        <Framework/>
        <CompetencyHoverTooltip />
        </Col>

       </Row>
        
    </FrameworkContextprovider>
 
  


   </>
  )
}
