import React from 'react'
import { Container } from 'react-bootstrap'
import ResetPassword from '../components/ResetPassword'
import { FaKey } from 'react-icons/fa'

export default function ResetPasswordPage() {
  return (
    <Container>
    <p className="appPageIcon">*<FaKey className="smallerIcon" /></p>
   <h2 className="appPageTitle"> | Reset password</h2>
   <ResetPassword/>
</Container>
  )
}

