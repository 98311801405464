// src/components/ContactForm.js

import React, { useState } from "react";
import axios from "axios";
import "./ContactUsForm.css";
const ContactUsForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");

    try {
      await axios.post("https://your-backend-api-url.com/api/send-email", {
        name,
        email,
        message,
      });

      setStatus("Message sent! We will get back to you soon.");
      setName("");
      setEmail("");
      setMessage("");
    } catch (error) {
      setStatus("Oops! Something went wrong. Please try again later.");
    }
  };

  return (
    <div className="contactFormContainer">
      <h3>Send us a messsage</h3>

      {status && <p className="status-message">{status}</p>}
      <form onSubmit={handleSubmit}>
        <div className="form-group row">
          <label htmlFor="name" >Name </label>
          <input
         
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="form-group row">
          <label htmlFor="email" >Email </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group row">
          <label htmlFor="message">Message </label>
          <textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          ></textarea>
        </div>
        <button type="submit">Send Message</button>
      </form>
    </div>
  );
};

export default ContactUsForm;
