import React from 'react'
import SignIn from '../components/SignIn'
import Header from '../components/Header';
import UserContextprovider from '../context/UserContext'
export default function SignInPage() {
  let signedIn=true
  return (
    <>
    <UserContextprovider>
    <div>
        <SignIn></SignIn>
    </div>
    </UserContextprovider>
    </>
  )
}
