import React,{useState,useContext} from 'react'
import axios from 'axios';
import config from '../api/config'
axios.defaults.withCredentials = true
const CourseContext=React.createContext();

export function useCourse(){
    return useContext(CourseContext)
}

export function CourseContextprovider({children}){
const [userCourses,setUserCourses]=useState([]);
const [selectedCourseId,setSelectedCourseId]=useState("");
const[message,setMessage]=useState({header:"",text:"",variant:"",buttons:[],isVisible:false})
const[selectedCourse,setSelectedCourse]=useState({})
const [actionType,setActionType]=useState("")
const [editModalIsVisible,setEditModalIsVisible]=useState(false)

const getCoursesByIds=async(idsArray)=>{
    const api= axios.create({baseURL:`${config.apiBaseURL}/courses`})
    await api.post('/'+idsArray).then(res=>{
        
        setUserCourses(res.data)
        
    } )
    .catch(error=> console.log(`Error: $error`));
}


const getUserCourses=async()=>{
    const api= axios.create({baseURL:`${config.apiBaseURL}/courses`})
        await api.get('/').then(res=>{
            
            setUserCourses(res.data)
            
        } )
        .catch(error=> console.log(`Error: $error`));
    }

    const saveCourse=()=>{

        let emptyCourse={name:"course name",code:"course code",description:"Course Description",deliveryMethode:"Online",goals:[],learningObjectives:[]}
        
        let api=axios.create({baseURL:`${config.apiBaseURL}/courses`})
        api.post('/', emptyCourse).then(response=>{ 
            
            getUserCourses()
            setSelectedCourseId(response.data._id)
        
        })
       
        
       
    }
    const updateCourse=async(courseID)=>{
        
        let api=axios.create({baseURL:`${config.apiBaseURL}/courses`});
        
        await api.put('/'+courseID, {
        ...selectedCourse
        })
        .then(response => {
            console.log(response);
            getUserCourses();
        })
        .catch(error => {
            console.log(error);
        });
    }
    const removeCourse=async (courseID)=>{
        console.log(courseID)
        const api= axios.create({baseURL:`${config.apiBaseURL}/courses`})
        await api.delete('/'+ courseID)
        await getUserCourses()
        
       
    }

    
    const value={
        userCourses,
        selectedCourseId,
        selectedCourse,
        message,
        getUserCourses,
        removeCourse,
        setEditModalIsVisible,
        editModalIsVisible,
        setSelectedCourseId,
        setSelectedCourse,
        setMessage,
        updateCourse,
        saveCourse,
        actionType,
        setActionType
    }

    return (
        <CourseContext.Provider value={value}>
            {children}
        </CourseContext.Provider>
    )
}

export default CourseContextprovider;