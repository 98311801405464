import React, { useEffect,useState } from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import { useSettings } from '../context/SettingContext'
import { FaMagic } from 'react-icons/fa'
import './ActivityEditor.css';
import config from '../api/config';
export default function ActivityEditor(props) {
    const { settingData, getSettingByName } = useSettings();
    const[activity,setActivity]=useState(props.activity)
    const [activityTypes,setActivityTypes]=useState([])
   

   
    const handleAIGenerateContent = async (e) => {
        e.preventDefault();
    
        let activityDescription = `The activity name is ${activity.name} with the type ${activity.type} covering in ROPE model the phase of ${activity.phase} described as ${activity.description}`;
        let learningObjective = props.loText;
    
        const eventSource = new EventSource(`${config.apiBaseURL}/ai-services?learningObjective=${encodeURIComponent(learningObjective)}&activityDescription=${encodeURIComponent(activityDescription)}`);
    
        // Event listener for new content chunks
        eventSource.onmessage = (event) => {
            setActivity(prevActivity => {
                // Replace double asterisks with line breaks in the event data
                const newContent = event.data.replace(/\*\*/g, '\n');
    
                // Append the new content
                const updatedContent = prevActivity.content + newContent;
                const updatedData = { ...prevActivity, content: updatedContent };
    
                // Call the parent function to save
                props.onSave(updatedData, props.index);
    
                return updatedData;
            });
        };
    
        // Handle errors in the EventSource connection
        eventSource.onerror = (err) => {
            console.error('EventSource failed:', err);
            eventSource.close();
        };
    };
    
    
    const handleActivityChange=(event)=>{
        let name=event.target.name;
        const updatedData={...activity,[name]:event.target.value}
        setActivity(updatedData)
        props.onSave(updatedData,props.index)
    }
    
   useEffect(()=>{
    if (!settingData?.domains) {
        getSettingByName("domains");
    }
    if(!settingData?.phases){
        getSettingByName("phases");
    }

    const getActivityTypes= (domainName, levelName) =>{
        const domain =  settingData?.domains.find(domain => domain.name === domainName);
        if (!domain) {
          return {}
        }
        
        const level =  domain?.levels?.find(level => level.name === levelName);
        if (!level) {
          return {}
        }
        
        return level.activityTypes;
      }
      let activityTypes = getActivityTypes(props.domain,props.level)
      if (Array.isArray(activityTypes)) {setActivityTypes(activityTypes)}
      else {
        setActivityTypes([])
      }
   },[settingData,props])
  return (
    <div className="activityEditor">
    
    <Row>
         <Col>
       
            
            <Row key={props.index}>
            <Col  lg={12} md={12} sm={12} xs={12} >
                    <strong>Activity Name</strong>
                    <Form.Control

                        name="name" 
                        type="text"
                        onChange={handleActivityChange}
                        value={activity.name}>
                    
                    </Form.Control>
                </Col>
                <Col  lg={8} md={8} sm={12} xs={12} >
                    <strong>Activity Type</strong>
                    <Form.Control name="type" as="select" 
                    onChange={handleActivityChange}
                    value={activity.type}>
                    {activityTypes?.map((activityType,index)=>(
                        <option key={index}>{activityType}</option>
                    ))}
                    </Form.Control>
                </Col>
                <Col lg={4} md={4} sm={12} xs={12}>
                    <strong>Activity Phase</strong>
                    <Form.Control 
                    name="phase" as="select" 
                    onChange={handleActivityChange}
                    value={activity.phase}>
                    {settingData?.phases?.map((phase,index)=>(
                        <option value={phase}>{phase}</option>
                    ))}
                    </Form.Control>
                </Col>
                <Col  lg={12} md={12} sm={12} xs={12} >
                    <strong>Activity Description</strong>
                    <Form.Control

                        name="description" 
                        type="text"
                        onChange={handleActivityChange}
                        value={activity.description}>
                    
                    </Form.Control>

                    
                </Col>
                </Row>
                <Row>
                <Col  lg={12} md={12} sm={12} xs={12} >
                    <strong>Activity Content</strong><button onClick={handleAIGenerateContent} className='aiButton'><FaMagic/> AI Content</button>
                    <Form.Control

                        name="content" 
                        as="textarea"
                        rows={5}
                        onChange={handleActivityChange}
                        value={activity.content}>
                    
                    </Form.Control>
                </Col>
            </Row>
       
        </Col>
    </Row>
    </div>
  )
}
