import React,{useEffect} from 'react'
import CourseView from './CourseView'
import GenericList from './GenericList'
import {useCourse} from '../context/CourseContext'
import{Row,Col} from 'react-bootstrap'
import './CourseList.css'
export default function CoursesList() {
  const {getUserCourses,setActionType,setEditModalIsVisible,removeCourse,saveCourse,userCourses,selectedCourseId,setSelectedCourseId,message,setMessage,setSelectedCourse,selectedCourse}=useCourse();
  
  useEffect(()=>{
    getUserCourses();  
    setMessage({target:"Courses",header:"",text:"",variant:"",buttons:[],isVisible:false});
  },[]);

  const handleSelect=async(event)=>{
    await setSelectedCourseId(event.target.getAttribute("selectid"))
    let currentCourse=await userCourses.find(course=>course._id===event.target.getAttribute("selectid"))
    if (currentCourse) {setSelectedCourse(currentCourse)}
  }
  const handleDelete=(event)=>{
    setSelectedCourseId(event.target.getAttribute("deleteid"));
    setMessage({target:"Courses",header:"Are you sure ?",text:"Are you sure you want to delete this course and all its related information?",variant:"warning",buttons:[{text:"Yes, I am sure",action:handleCompleteDelete},{text:"Cancel",action:handleCancel}],isVisible:true})

  }
  const handleCompleteDelete=()=>{
    removeCourse(selectedCourseId);
    setMessage({target:"Courses",header:"",text:"",variant:"",buttons:[],isVisible:false});
  }
  const handleCancel=()=>{
    setMessage({target:"Courses",header:"",text:"",variant:"",buttons:[],isVisible:false});
  }
  const handleEdit=async(event)=>{
    await setActionType("edit")
    await setSelectedCourseId(event.target.getAttribute("editid"));
    let currentCourse=await userCourses.find(course=>course._id===event.target.getAttribute("editid"))
    if (currentCourse) {await setSelectedCourse(currentCourse)}
    setEditModalIsVisible(true)
  }
  const handleAdd=async()=>{
    await saveCourse();
  }

  return (
    <div className="courseListContainer">
    <Row>
      <Col lg="3" md="4" sm="12" sx="12">
      <GenericList
        listHeader="Courses"
        columnsHeaders={["Name","Description"]}
        columnsNames={["name","description"]}
        data={userCourses}
        handleSelect={handleSelect}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        handleAdd={handleAdd}
        selectedItemId={selectedCourseId}
        listMessage={message}
      />
      </Col>
      <Col lg="9" md="8" sm="12" sx="12">
      
      <CourseView
        data={selectedCourse}
      />
      </Col>
    </Row>

    </div>
  )
}




