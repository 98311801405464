import React, {useContext,useState,useEffect} from "react";
import axios from 'axios';
import config from '../api/config'
axios.defaults.withCredentials = true
const HelpCategoryContext=React.createContext();
export function useHelpCategory(){
    return useContext(HelpCategoryContext)
}

export function HelpCategoryContextprovider({children}){
const [userHelpCategories,setUserHelpCategories]=useState([]);

const getHelpCategories= async(categoryName)=>{
    const api=axios.create({baseURL:`${config.apiBaseURL}/helpcategories`})
    await api.get('/').then(res=>{
        
         let resultCategories=res.data;
         if (categoryName!==null){
            resultCategories=resultCategories.filter(helpCategory=>helpCategory.category===categoryName)
            
         }
         setUserHelpCategories(resultCategories)
        
    } )
    .catch(error=> console.log(`Error: $error`));
}

const getHelpCategoryByName=async(categoryName)=>{
    
    if (userHelpCategories===[]){
        await getHelpCategories();
    }
    let thisHelpCategory=[...userHelpCategories];
    thisHelpCategory.filter(helpCategory=>helpCategory.category===categoryName)
    return(thisHelpCategory)

}
const value={
   userHelpCategories,
   getHelpCategoryByName,
   getHelpCategories
};

return (
    <HelpCategoryContext.Provider value={value}>
        {children}
    </HelpCategoryContext.Provider>
)
}
export default HelpCategoryContextprovider;