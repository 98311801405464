import React from 'react'

import SignUp from '../components/SignUp'
import Header from '../components/Header'
import UserContextprovider from '../context/UserContext'
export default function SignUpPage() {
  
  return (
    <>
    <UserContextprovider>
      <div>
          <SignUp></SignUp>
      </div>
    </UserContextprovider>
      </>

  )
}
