import React,{useState} from 'react'
import {Col,Container,Row} from 'react-bootstrap'
import {FaCaretRight,FaCaretDown} from 'react-icons/fa'
import './CollapsableItem.css'
import parse from 'html-react-parser';
import DOMPurify from 'dompurify'
export default function CollapsableItem(props) {
  const [isCollapsed,setIsCollapsed]=useState(false)
  const handleToggleCollapse=()=>{
    setIsCollapsed(!isCollapsed)
  }
  return (
    
            <>
            <Container>
            <Row>
                <Col className="subject">
                <h3 onClick={handleToggleCollapse} >
                <div style={{display:"inline-block",}}>{isCollapsed?<FaCaretDown/>:<FaCaretRight/>}</div>
                  &nbsp; <div className="subjectTitle">{parse(props.subject.title)}</div> 
                </h3>
                <Row>
                  
                  {isCollapsed?<><Col lg="6" md="7" xs="12" sm="12">
                    <p className="subjectDescription">{parse(DOMPurify.sanitize( props.subject.description))}</p>
                    </Col>
                  {(props.subject.image.length>0)?<Col lg="6" md="5" xs="12" sm="12"><img width="100%" src={`data:image/png;base64,${props.subject.image}`} alt={parse(props.subject.title)}/></Col>:null}
                 
                  </>:null}
                </Row>
               
                </Col>
            </Row>
            </Container>
            </>
        )

    
  
}
