import React from 'react'
import ForgotPassword from '../components/ForgotPassword'
import { FaFrown,FaKey } from 'react-icons/fa'
import { Container } from 'react-bootstrap'
import './ForgotPasswordPage.css'
export default function ForgotPasswordPage() {
  return (
    <Container>
         <p className="appPageIcon"><FaFrown /><FaKey className="smallerIcon" /></p>
        <h2 className="appPageTitle"> | Forgot password</h2>
        <ForgotPassword/>
    </Container>
  )
}
