import React, { useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import './Framework.css';
import Role from './Role';
import ColorCodeSelector from './ColorCodeSelector';
import { useFramework } from '../context/FrameworkContext';
import { useReactToPrint } from 'react-to-print';
import { FaPrint } from 'react-icons/fa';
export default function FrameWork() {
  const { framework } = useFramework();
  const frameworkRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => frameworkRef.current,
    documentTitle: framework?.frameworkName || 'Framework Report',
  });

  return (
    <>
      {/* Wrap all content inside the ref */}
      <div ref={frameworkRef} style={{ padding: '20px' }}>
        <Row className="frameworkInfoBlock">
          <Col>
          
            <div className="frameworkInfo">
              <div className="frameworkName">{framework?.frameworkName}</div> 
              <div className="frameworkDescription">{framework?.frameworkDescription}</div>
            </div>
          </Col>
          <Col xs="3" sm="2" md="2" lg="1"><button title="Print" onClick={handlePrint} className="btn btn-primary printButton" style={{ marginTop: '20px' }}><FaPrint /></button></Col>
        </Row>
        <Row>
          <Col></Col>
          <Col xs="12" sm="6" md="4" lg="3">
            <ColorCodeSelector />
          </Col>
        </Row>
        <Row style={{ width: '100%' }}>
          {framework?.roles.map((role, index) => {
            return (
              <Col key={index} xs="12" sm="12" md="6" lg="6">
                <Role
                  key={role._id}
                  id={role._id}
                  roleName={role.name}
                  roleDescription={role.description}
                />
              </Col>
            );
          })}
        </Row>
      </div>

     
    </>
  );
}
