import React, {useContext,useState,useEffect} from "react";
import { useAuth } from "./AuthContext";
//import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from '../api/axios';
const UserContext=React.createContext();
export function useUser(){
    return useContext(UserContext)
}

export function UserContextprovider({children}){
    
   // const [isUsingGoogle,setISUsingGoogle]=useState(false)
    const {setAuth}=useAuth();

   /* const loginGoogle = useGoogleLogin({
        onSuccess: (codeResponse) => setUser(codeResponse),
        onError: (error) => console.log('Login Failed:', error)
    });

    const getGoogleUser=() => {
        if (user) {
            axios
                .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                    headers: {
                        Authorization: `Bearer ${user.access_token}`,
                        Accept: 'application/json'
                    }
                })
                .then((res) => {
                    setProfile(res.data);
                })
                .catch((err) => console.log(err));
        }
    }
    const logOutGoogle = () => {
        googleLogout();
        setProfile(null);
        setISUsingGoogle(false)
    };*/

    const loginGeneric=async(email,password)=>{
        
        const LOGIN_URL='users/login'
        try{
            const response=await axios.post(LOGIN_URL,{
                headers:{'content-type':'application/json'},withCredentials:true,
                data:{email,password}
            })
            setAuth(response?.data)
            console.log(response?.data)
            return ({success:true,message:"login successful"})
        }
    
        catch(err){
            setAuth({})
            if(!err?.response){
                return {success:false,message:"No Server Response"}
               
            } else if(err.response?.status===401){
                return {success:false,message:"Missing Username or Password"}
            } else {
                return {success:false,message:"Login Failed"}
            }
    
        }

    }
    






const registerUser=async(user)=>{
    const USER_URL='users'
    
    try{
        await axios.post(USER_URL,{
            headers:{'content-type':'application/json'},withCredentials:true,
            data:user
        })
        return {success:true,message:"Successfuly registered"};
    }

    catch(err){
        
        if(!err?.response){
            return {success:false,message:"No Server Response"}
        } else if(err.response?.status===409){
            return {success:false,message:"Name Taken"}
        } else {
            return {success:false,message:"Registration Failed"}
        }

    }
}
    

const value={
   registerUser,
   loginGeneric,

};

return (
    <UserContext.Provider value={value}>
        {children}
    </UserContext.Provider>
)
}
export default UserContextprovider;
