import React from 'react'
import {Row,Col} from 'react-bootstrap'
import Competency from './Competency'
import {FaCubes} from 'react-icons/fa'
import './Function.css'
import { useFramework } from '../context/FrameworkContext'
export default function Function(props) {
  const {competencySettings,coloredBy}=useFramework()
  
  return (
   <Row>
    <Col xs="7"  sm="7"  md="7"  lg="7" className="functionBorder ">
        <div className="functionName"><div className="inverse"><FaCubes />&nbsp;</div> {props.functionName}</div>
       <div className="functionDescription">{props.functionDescription}</div>
    </Col>
    <Col xs="5"  sm="5"  md="5"  lg="5" className="functionBorder">
    {props.competencies.map((competency,index)=>{
        
        let competencyColor=competencySettings[coloredBy].find(element=>element.label===competency[coloredBy])
        console.log(competencyColor)
        return( <Competency key={index}

            theCompetencyInfo={{...competency,color:competencyColor.color}}
            color={competencyColor.color}
            borderColor={competency.borderColor}
            />
        )
    })}
     
    </Col>
   </Row>
  )
}
