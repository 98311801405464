import React from 'react'
import {Row,Col} from 'react-bootstrap'
import frameworkIcon from '../assets/frameworkIcon.svg'
import { FaBoxes, FaCertificate, FaChalkboardTeacher, FaTable } from 'react-icons/fa';
import './IconBlock.css'
export default function IconBlock() {
  return (
    <Row>
    <Col>
    <Row className="iconBlocksArea">
      <Col>
      <div><FaBoxes className="blockIcon "/></div>
        <div className="blockText blockBlue">Represent your competency framework using multi-level system emphasizing roles, functions, competencies</div>
      </Col>
      <Col>
        <div><FaTable className="blockIcon "/></div>
        <div className="blockText  blockGray">Organize Your programs with information concerning the target audience and the desdetails for each of the programs</div>
      </Col>
      <Col>
       <div><FaChalkboardTeacher className="blockIcon "/></div>
        <div className="blockText blockYellow ">Edit Learning Objectives and designing learning activities following adult learning theories and best practices</div>
      </Col>
      <Col>
        <div><FaCertificate className="blockIcon "/></div>
        <div className="blockText blockWhite">Map your certificates to the created frameworks' competencies to and vidualize the progress directly assure covering diffrent roles </div>
      </Col>
    </Row>
    </Col>
  </Row>
  
  )
}
