import React, {useContext,useState,useEffect } from "react"
import { redirect } from "react-router-dom";
import axios from "../api/axios";
import config from "../api/config";

const AuthContext=React.createContext();

export function useAuth(){
    return useContext(AuthContext)
}

export function AuthContextprovider({children}){
    const [user, setUser] = useState(null);
    const [isAuthenticated,setIsAuthenticated]=useState(false)
    const [hasCheckedSession,setHasCheckedSession]=useState(false)
    const [loading,setLoading]=useState(false)
 


    const login= async ({ email, password, provider } = { provider: 'local' }) => {
        try {
          let response;
          if (provider === 'local') {
            response = await axios.post('/auth/local', { email, password }, { withCredentials: true });
          } else if (provider === 'google' || provider === 'facebook') {
            console.log(`${config.apiBaseURL}/auth/${provider}`)
            window.location.href =  `${config.apiBaseURL}/auth/${provider}`;
            return ;
          }
          if (response && response.data.user) {

            setUser(response.data.user);
            setIsAuthenticated(true);
            return response.data.message;
          }
        } catch (error) {
          console.error('Login failed:', error);
          return {message:{success:false,message:"Login Failed"}}
        }
      }

     
      const checkSession = async () => {
        
        try {
            const res = await axios.get('/auth/session',{ withCredentials: true })
            if (res.data.user) {
                
                setUser(res.data.user);
                setIsAuthenticated(true);
                

            } else {
                throw new Error('Not authenticated');
            }
        } catch (error) {
            console.error(error)
            setUser(null);
            setIsAuthenticated(false);

        } finally {
            setHasCheckedSession(true);
            setLoading(false);
        }
       

    }
    useEffect(() => {
        console.log("isAuthenticated changed:", isAuthenticated);
    }, [isAuthenticated]);
    const logout = async () => {
        
        await axios.post('/auth/logout');
        setUser(null);
        setIsAuthenticated(false);
        redirect('/signin');
    };
    const registerUser=async(user)=>{ 
      try{
          await axios.post('/auth/register',{
              headers:{'content-type':'application/json'},withCredentials:true,
              data:user
          })
          return {success:true,message:"Successfuly registered"};
      }
  
      catch(err){
          
          if(!err?.response){
              return {success:false,message:"No Server Response"}
          } else if(err.response?.status===409){
              return {success:false,message:"Name Taken"}
          } else {
              return {success:false,message:"Registration Failed"}
          }
  
      }
  }
  const changePassword=async(currentPassword,newPassword)=>{
   
    try{
        
        const response=await axios.put('/auth/change-password',{currentPassword,newPassword},{
           withCredentials:true}
        )
        console.log(response.data)
        return response.data;
    }
    catch(err){
        
        if(!err?.response){
            return {success:false,message:"No Server Response"}
        } else if(err.response?.status===409){
            return {success:false,message:"resource conflict something went wrong password was not changed"}
        } else {
            return {success:false,message:"Something went wrong password was not changed"}
        }
    }
  }

  const updateUserDetails=async(details)=>{
    try{
        
        const response=await axios.post('/auth/update-user-details',details,{
           withCredentials:true,
           headers: {
            // Let axios automatically set Content-Type to multipart/form-data
          }}
        )
        return response.data;
    }
    catch(err){
        if(!err?.response){
            return {success:false,message:"No Server Response"}
        } else if(err.response?.status===409){
            return {success:false,message:"resource conflict something went wrong password was not changed"}
        } else {
            return {success:false,message:"Something went wrong password was not changed"}
        }
    }
  }
  const getUserDetails=async()=>{
    try{
        const response=await axios.get('/auth/get-user-details',{
           withCredentials:true}
        )
        console.log(response.data)
        return response.data;
    }
    catch(err){
        if(!err?.response){
            return {success:false,message:"No Server Response"}
        } else if(err.response?.status===409){
            return {success:false,message:"resource conflict something went wrong password was not changed"}
        } else {
            return {success:false,message:"Something went wrong password was not changed"}
        }
    }
  }
    const value={user,login,logout,isAuthenticated, registerUser,checkSession, hasCheckedSession, loading,changePassword,updateUserDetails, getUserDetails};

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContextprovider;