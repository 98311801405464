import React,{useState} from 'react'
import {Badge} from 'react-bootstrap';
import './ContactBadge.css'
export default function ContactBadge(props) {
const [detailsVisible,setDetailsVisible]=useState(false);

const handleMouseEnter=()=>{
    setDetailsVisible(true)
}
const handleMouseOut=()=>{
    setDetailsVisible(false)
}

  return (
    <>
       
       &nbsp;<Badge className="badgeTitle" onMouseMove={handleMouseEnter} onMouseOut={handleMouseOut}>{props.data.fullName}</Badge>
       {detailsVisible?
       <div className="badgeDetails" >
        {props.columnsNames.map((columnName)=>{

            return(
            <>
            <div className="badgeDetail">
              <b>{columnName.label} : </b>{props.data[columnName.columnDBName]}
            </div>
            
          
            </>
            )
        })}
        </div>
        :null}
       
   </>
    
  )
}
