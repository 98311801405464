import React from 'react'
import ContactUsForm from '../components/ContactUsForm'
import { FaEnvelope, FaPhone, FaMapMarkerAlt,FaRegEnvelope } from 'react-icons/fa';
import { Row, Col, Container } from 'react-bootstrap'
import './ContactUsPage.css'
import Header from '../components/Header'
export default function ContactUsPage() {
  return (
    <>
    <Container>
    <p className="appPageIcon"><FaRegEnvelope /></p>
    <h2 className="appPageTitle"> | Contact Us</h2>
    <div className="homeContainer">
    <p>If you have any questions, feel free to reach out to us. We are here to help!</p>
      
      
      
      
    <Row className="gx-5">

      <Col lg="7" md="7" sm="12" xs="12" >
      <div className="mapPart">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2514.651844429079!2d-114.02674952260224!3d50.93015705270731!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x537176fcdb0e3ed3%3A0xd6c348557c7276f7!2sDeerview%20Heights%20SE%2C%20Calgary%2C%20AB%20T2J%207C1!5e0!3m2!1sen!2sca!4v1721944380948!5m2!1sen!2sca" width="100%" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
      </Col>
      <Col lg="5" md="5" sm="12" xs="12">
      <div className="infoPart">
        <p><FaEnvelope /> <strong>Email</strong> <a href="mailto:info@eduframework.ca">info@eduframework.ca</a> </p>
      
        <p><FaPhone /> <strong>Phone </strong> +1 (587)287-3005</p>
     
        <p><FaMapMarkerAlt /> <strong>Address </strong> Deerview Hts SE, Calgary, AB, Canada</p>
        </div> 
      <div className="formPart">
        <ContactUsForm />
      </div>
      </Col>
    </Row>
    </div>
    </Container>
    </>
    

    
  )
}
