import React  from 'react'
import {Row,Col,Form} from 'react-bootstrap'
import {useCertificate} from '../context/CertificateContext'
export default function Certificateditor(props) {
 
  const {selectedCertificate,setSelectedCertificate}=useCertificate();
  
  
  const handleInputChange=(event)=>{
    let value=event.target.value;
   
    let updatedUserCertificate={...selectedCertificate};
    let schema=updatedUserCertificate;
    let path=event.target.name;
    let nestedItemsList=path.split('.');
    let nestedLevels=nestedItemsList.length;
    for (let i=0;i<nestedLevels-1;i++){
      let element=nestedItemsList[i];
      if (!schema[element]) schema[element]={}
      schema=schema[element];
    }
    schema[nestedItemsList[nestedLevels-1]]=value
    setSelectedCertificate({...updatedUserCertificate})
    console.log(selectedCertificate);

}


 
  return (
      <>
    <Row>
      <Col lg="3" md="4" xs="5" sm="4" className="appEditorLabel frameworkEditorLabel ">
        <Form.Label >Certificate Title</Form.Label>
      </Col>
        <Col>
            <Form.Control   name="title"
            type="text" 
            defaultValue={selectedCertificate.title}
            onChange={handleInputChange} 
            ></Form.Control> 
        </Col>
    </Row>
    <Row>
      <Col lg="3" md="4" xs="5" sm="4" className="appEditorLabel frameworkEditorLabel ">
        <Form.Label >Issued By</Form.Label>
      </Col>
      <Col>
        <Form.Control   name="issuedBy"
        type="text" 
        defaultValue={selectedCertificate.issuedBy}
        onChange={handleInputChange} 
        ></Form.Control>
      </Col>
    </Row>
    <Row>
      <Col lg="3" md="4" xs="5" sm="4" className="appEditorLabel frameworkEditorLabel ">
        <Form.Label >Certificate for</Form.Label>
      </Col>
      <Col>
        <Form.Control as="select" name="certificateFor"
        
        defaultValue={selectedCertificate.certificateFor}
        onChange={handleInputChange} 
        >
          <option value="Course">Course</option>
          <option value="Program">Program</option>
          <option value="Level">Level</option>
          <option value="Framework">Framework</option>

        </Form.Control>
      </Col>
    </Row>
    <Row>
      <Col lg="3" md="4" xs="5" sm="4" className="appEditorLabel frameworkEditorLabel ">
        <Form.Label >Acheivement Description</Form.Label>
      </Col>
      <Col>
        <Form.Control name="acheivementDescription"
        type="text" 
        defaultValue={selectedCertificate.acheivementDescription}
        onChange={handleInputChange} 
        ></Form.Control>
      </Col>
    </Row>
    <Row>
      <Col lg="3" md="4" xs="5" sm="4" className="appEditorLabel frameworkEditorLabel ">
        <Form.Label >Validity Duration</Form.Label>
      </Col>
      <Col>
        <Form.Control name="validityDuration"
        type="text" 
        defaultValue={selectedCertificate.validityDuration}
        onChange={handleInputChange} 
        ></Form.Control>
      </Col>
    </Row>
    <Row>

    </Row>
  
    
    

   
    </>
  )
}
