import React from 'react'
import { PayPalButtons,PayPalScriptProvider } from '@paypal/react-paypal-js';

export default function PayPalPaymentComponent(props) {
  return (
    <PayPalScriptProvider option={{"client-id":"AcWesfvyAYASLC8DArXtu74bFHkzj1W3dYq6AISZswkrEmrxGkCG7AIbVVpmGzy5OSK_7F-VAGR6ZIji","currency": "CAD"}}>
    <PayPalButtons
        fundingSource="card"
        createOrder={(data, actions) => {
            return actions.order.create({
                purchase_units: [
                    {
                        amount: {
                            value: props.price,
                            currency:"CAD"
                        },
                    },
                ],
            });
        }}
        onApprove={async(data, actions) => {
            return actions.order.capture().then((details) => {
                const name = details.payer.name.given_name;
                alert(`Transaction completed by ${name}`);
            });
        }}>

    </PayPalButtons>
  </PayPalScriptProvider>
  )
}
